<template>
  <v-card
    class="app-card-code"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <!-- Title -->
    <v-card-title>
      {{ title }}
    </v-card-title>
    <div class="demo-content">
      <slot></slot>
    </div>
  </v-card>
</template>

<script>
import { ref } from '@vue/composition-api'
// eslint-disable-next-line object-curly-newline
import { mdiCodeTags, mdiLanguageJavascript, mdiLanguageTypescript, mdiContentCopy, mdiCheck } from '@mdi/js'

// PrismJS
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'

export default {
  components: {},
  props: {
    title: {
      type: String,
      required: true,
    },
  },
  setup() {
    const isCodeCollapsed = ref(false)

    return {
      isCodeCollapsed,

      // Icons
      icons: {
        mdiCodeTags,
        mdiLanguageJavascript,
        mdiLanguageTypescript,
        mdiContentCopy,
        mdiCheck,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
@import '~vuetify/src/components/VCard/_variables.scss';
@import '~vuetify/src/components/VBtn/_variables.scss';
@import '~vuetify/src/styles/styles.sass';

pre[class*='language-'] {
  margin: 0;
  border-radius: $card-border-radius;
  max-height: 350px;
}

.demo-content {
  &:last-child {
    padding-bottom: $card-spacer;
  }
}
</style>
